define("discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-personas/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem as |linkClass|>
    <LinkTo @route="adminPlugins.show.discourse-ai-personas" class={{linkClass}}>
      {{@model.plugin.nameTitleized}}
    </LinkTo>
  </DBreadcrumbsItem>
  
  <AiPersonaListEditor
    @personas={{this.allPersonas}}
    @currentPersona={{this.model}}
  />
  */
  {
    "id": "+9Rjfods",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[16,0,[30,1]]],[[\"@route\"],[\"adminPlugins.show.discourse-ai-personas\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"plugin\",\"nameTitleized\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@personas\",\"@currentPersona\"],[[30,0,[\"allPersonas\"]],[30,0,[\"model\"]]]],null]],[\"linkClass\",\"@model\"],false,[\"d-breadcrumbs-item\",\"link-to\",\"ai-persona-list-editor\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-personas/new.hbs",
    "isStrictMode": false
  });
});